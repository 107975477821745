// import firebase from "gatsby-plugin-firebase"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

import { db as fakeDb, auth as fakeAuth } from "./db.fake"

const config = {
  apiKey: "AIzaSyBoA0tq_1qp0X73QMtR3cWJrHOygAWVJr8",
  authDomain: "lights-on-leyden.firebaseapp.com",
  databaseURL: "https://lights-on-leyden.firebaseio.com",
  projectId: "lights-on-leyden",
  storageBucket: "lights-on-leyden.appspot.com",
  messagingSenderId: "361792879145",
  appId: "1:361792879145:web:19e42f2a4550424c493a38",
}

firebase.initializeApp(config)

let fdb = fakeDb,
  fauth = fakeAuth

if (typeof window !== "undefined") {
  fdb = firebase.firestore()
  fauth = () => firebase.auth()
}

export const db = fdb
export const auth = fauth
export const mode = "real"

// let instance = null

// export function getFirebase() {
//   if (typeof window !== "undefined") {
//     if (instance) return instance
//     instance = firebase.initializeApp(config)
//     return instance
//   }

//   return null
// }
